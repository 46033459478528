var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "h-screen flex w-full bg-img" }, [
    _c(
      "div",
      {
        staticClass:
          "vx-col sm:w-3/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center",
      },
      [
        _c("vx-card", [
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              {
                staticClass:
                  "vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center  d-theme-dark-bg",
              },
              [
                _c(
                  "div",
                  { staticClass: "p-8" },
                  [
                    _c(
                      "div",
                      { staticClass: "vx-card__title mb-8 instructions" },
                      [
                        _c("h4", { staticClass: "mb-4" }, [
                          _vm._v("Cambiar contraseña"),
                        ]),
                        _vm.email != null
                          ? _c("p", [
                              _vm._v("El usuario con email "),
                              _c("b", [_vm._v(_vm._s(_vm.email))]),
                              _vm._v(" ha solicitado cambiar la contraseña."),
                            ])
                          : _vm._e(),
                        _c("br"),
                        _c("h6", [
                          _vm._v("Por favor ingrese su nueva contraseña."),
                        ]),
                      ]
                    ),
                    _vm.stateMessage
                      ? _c(
                          "vs-alert",
                          {
                            staticClass: "alert-message",
                            attrs: {
                              title: "Globalwork dice:",
                              active: _vm.stateMessage,
                              color: _vm.colorMessage,
                            },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.message) +
                                "\n            "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _c("vs-input", {
                      staticClass: "w-full mb-6",
                      attrs: {
                        type: "password",
                        "label-placeholder": "Contraseña",
                      },
                      model: {
                        value: _vm.user.password,
                        callback: function ($$v) {
                          _vm.$set(_vm.user, "password", $$v)
                        },
                        expression: "user.password",
                      },
                    }),
                    _c("vs-input", {
                      staticClass: "w-full mb-8",
                      attrs: {
                        type: "password",
                        "label-placeholder": "Confirmar contraseña",
                      },
                      model: {
                        value: _vm.user.password_confirmation,
                        callback: function ($$v) {
                          _vm.$set(_vm.user, "password_confirmation", $$v)
                        },
                        expression: "user.password_confirmation",
                      },
                    }),
                    _c(
                      "div",
                      { staticClass: "flex flex-wrap justify-between my-5" },
                      [
                        _c("router-link", { attrs: { to: "/pages/login" } }, [
                          _vm._v("Volver al inicio de sesión"),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "flex flex-wrap justify-between flex-col-reverse sm:flex-row",
                      },
                      [
                        _c(
                          "vs-button",
                          {
                            staticClass: "w-full sm:w-auto",
                            on: { click: _vm.change },
                          },
                          [_vm._v("Cambiar")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }