<template>
  <div class="h-screen flex w-full bg-img">
    <div class="vx-col sm:w-3/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center">
      <vx-card>
        <div class="vx-row">
          <div class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center  d-theme-dark-bg">
            <div class="p-8">
              <div class="vx-card__title mb-8 instructions">
                <h4 class="mb-4">Cambiar contraseña</h4>
                <p v-if="email != null">El usuario con email <b>{{email}}</b> ha solicitado cambiar la contraseña.</p>
                <br>
                <h6>Por favor ingrese su nueva contraseña.</h6>
              </div>
              <vs-alert class="alert-message" title="Globalwork dice:" :active="stateMessage" :color="colorMessage" v-if="stateMessage">
                {{message}}
              </vs-alert>
              <vs-input type="password" label-placeholder="Contraseña" v-model="user.password" class="w-full mb-6" />
              <vs-input type="password" label-placeholder="Confirmar contraseña" v-model="user.password_confirmation" class="w-full mb-8" />
              <div class="flex flex-wrap justify-between my-5">
                <router-link to="/pages/login">Volver al inicio de sesión</router-link>
              </div>
              <div class="flex flex-wrap justify-between flex-col-reverse sm:flex-row">
                <vs-button @click="change" class="w-full sm:w-auto">Cambiar</vs-button>
              </div>

            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import api from './../../mixins/api.js'
export default {
  mixins: [api],
  data() {
    return {
      reset_password_token: '',
      email: null,
      user: {        
        password: '',
        password_confirmation: ''
      },
      stateMessage: false,
      colorMessage: '',
      message: ''
      
    }
  },
  methods: {
    showMessage(stateMessage, message, colorMessage){
      this.stateMessage = stateMessage
      this.message = message
      this.colorMessage = colorMessage
    },    
    change() {
      if ((this.user.password.length >= 8 && this.user.password_confirmation.length >= 8) && (this.user.password == this.user.password_confirmation)) {
        this.showMessage(true, 'Cambiando la contraseña ...', 'success')
        this.requestPutWithoutHeaders(process.env.VUE_APP_ROOT_INTRANET + 'api/v1/reset_password', { reset_password_token: this.reset_password_token, user: this.user}).then(response => {
          if (response.data.status == 200){
            this.showMessage(true, `${response.data.message}. Puede iniciar sesión con su nueva clave.`, 'success')
            setTimeout(() => this.$router.push('/pages/login'), 20000)            
          } else{
            this.showMessage(true, response.data.message, 'danger')
          }
          
        }, (err) => {
          this.showMessage(true, `No se pudo cambiar la clave ${err}`, 'danger')
        })
      } else {
        this.showMessage(true, 'Contraseña debe tener al menos 8 carácteres y debe coincidir con la confirmación de la contraseña', 'danger')
      }
    }
  },
  created () {
    this.reset_password_token = this.$route.query.reset_password_token
    this.email = this.$route.query.email
  },
}
</script>
<style>
  .alert-message{
    height: 100% !important;
    margin-bottom: 2rem !important;
  }
  .instructions {
    padding-bottom: 0rem;
  }
</style>